<template>
  <div class="inbox">
    <watch-head :title="$t('消息/聊天')"
                :img="require('@/assets/bracelet_img/67.png')" />
    <div class="main">
      <div class="collect" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('收件箱')}} :</div>
      <div class="unread" :class="{'vn-italic': $i18n.locale == 'vn'}">{{unreadCount}} {{$t('消息 - 未读')}}</div>

      <van-list v-model="loading"
                :finished="finished"
                :finished-text="$t('没有更多了')"
                :immediate-check="false"
                @load="getMsgList"
                class="list">
        <div class="item"
             v-for="(item, index) in msgList"
             :key="index">
          <div class="left">
            <div class="img">
              <img :src="item.avatar"
                   alt="">
              <div class="text">{{item.level_no}}</div>
            </div>
            <div class="info">
              <div class="name" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{item.nickname || $t('暂无昵称')}}</div>
              <div class="Ranking" :class="{'vn-italic': $i18n.locale == 'vn'}">{{$t('排行')}} : {{item.top}}</div>
            </div>
          </div>
          <van-badge  :content="item.wd > 0 ? item.wd : '' " max="99">
            <div class="right"
                 @click="goTo('/message', item.from_uid)">
              <img src="@/assets/bracelet_img/69.png"
                   alt="">
            </div>
          </van-badge>

        </div>
      </van-list>
    </div>
    <chat-foot :content='unreadCount' />
  </div>
</template>
<script>
import watchHead from '@/components/watchHead/index.vue'
import chatFoot from '@/views/chat/components/chatFoot.vue'
import { MsgListApi } from '@/api/api'
export default {
  components: {
    watchHead,
    chatFoot
  },
  data () {
    return {
      message: '',
      loading: false,
      finished: false,
      msgListFrom: {
        page: 1,
        limit: 10
      },
      msgList: [],
      unreadCount:'',

    }
  },
  created () {
    this.waitForGlobal()
  },
  methods: {
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token')  && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.loading = true
        this.finished = false
        this.msgListFrom.page = 1
        this.msgList = []
        this.getMsgList()

      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    /* 获取聊天记录 */
    getMsgList () {
      if (this.finished) return
      this.loading = true;
      this.finished = false
      MsgListApi(this.msgListFrom).then(res => {
        if (res.code == 0) {
          if (res.data.data.length < this.msgListFrom.limit) {
            this.finished = true
          }
          this.msgList = this.msgList.concat(res.data.data)
          this.unreadCount = res.data.unread_count
          // console.log(this.msgList)
          this.loading = false;
          this.msgListFrom.page++
        }
      })
    },
    goTo (url, fromUid) {
      this.$router.push({
        path: url,
        query:{
          fromUid
        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
.inbox {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 80px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  .main {
    margin-top: 27px;
    padding: 0 23px;
    .collect {
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 29px;
    }
    .unread {
      font-family: 'TsangerYuYangT-W05';
      font-size: 12px;
      margin: -4px 0 8px;
    }
    .list {
      height: 65vh;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10 */
      .item {
        width: 100%;
        height: 73px;
        background-image: linear-gradient(
            237deg,
            rgba(118, 233, 214, 0.83) 0%,
            rgba(159, 174, 230, 0.83) 100%
          ),
          linear-gradient(#ffffff, #ffffff);
        background-blend-mode: normal, normal;
        border-radius: 8px;
        // opacity: 0.75;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        .left {
          display: flex;
          align-items: center;
          .img {
            width: 57px;
            height: 57px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: solid 3px #2b2b2b;
            position: relative;
            margin-right: 21px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 26px;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -13px;
              bottom: 0;
              font-family: 'AeroMaticsDisplayBoldItalic';
            }
          }
          .info {
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 22px;
            }
            .Ranking {
              font-family: 'TsangerYuYangT-W03';
              font-size: 12px;
            }
          }
        }
        /deep/.van-badge--fixed {
          top: 5px;
          right: 5px;
        }
        .right {
          width: 39px;
          height: 39px;
          background-color: #a7f264;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            width: 23px;
            height: 21px;
          }
        }
      }
    }
    .list::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>